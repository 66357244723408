<template>
  <div id="login-page">
    <v-flex xs12>
      <v-layout wrap class="login" v-if="!isMobile">
        <v-flex xs12 sm6 md4 offset-md2>
          <div class="box">
            <span class="text uppercase"> {{ $t('login-form.login')}} </span>
            <v-form
              ref="form"
              class="mt-9"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="email"
                ref="email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :type="seePassword ? 'text' :'password'"
                ref="password"
                label="Password"
                :append-icon="seePassword ? 'mdi-eye-off' :'mdi-eye'"
                @click:append="seePassword= !seePassword"
                :rules="passRules"
                required
              ></v-text-field>

              <p class="p-text">
                <router-link class="link-f" to="/forget-password">{{ $t('login-form.forget')}} </router-link>
              </p>

              <v-btn class="mt-5" tile color="black" dark width="100%" height="44px" @click="login()">
                <span class="btn-text"> {{ $t('login')}} </span>
              </v-btn>
            </v-form>
          </div>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <div class="box">
            <span class="text uppercase"> {{ $t('login-form.register')}} </span>
            <p class="p-text">
              {{ $t('login-form.subtitle') }}, {{ $t('login-form.subtitle2')}}
            </p>
            <p class="p-text">
              {{ $t('login-form.subtitle3')}} {{ appName }}.
            </p>
            <router-link class="btn-text" to="/register">
              <v-btn class="mt-5" tile color="black" dark width="100%" height="44px">
                {{ $t('login-form.sign-up')}} 
              </v-btn>
            </router-link>
          </div>
        </v-flex>
      </v-layout>
      
      <v-layout wrap class="login" v-else>
        <v-flex xs12>
          <div class="box-2">
            <span class="text-2"> {{ $t('login-form.login')}}  </span>
            <v-form
              ref="form"
              class="mt-9"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                outlined
                v-model="email"
                ref="email"
                label="Email"
                :rules="emailRules"
                required
              ></v-text-field>

              <v-text-field
                outlined
                :type="seePassword ? 'text' :'password'"
                v-model="password"
                ref="password"
                :label="$t('login-form.password')"
                :rules="passRules"
                :append-icon="seePassword ? 'mdi-eye-off' :'mdi-eye'"
                @click:append="seePassword= !seePassword"
                required
              ></v-text-field>

              <v-btn class="mt-5" tile color="black" dark width="100%" height="44px" @click="login()">
                <span class="btn-text">  {{ $t('login-form.user-login')}}  </span>
              </v-btn>
            </v-form>

            <p class="p-text">
              <router-link class="link-f" to="/forget-password">{{ $t('login-form.forget')}} </router-link>
            </p>

            <p class="p-text">
              {{ $t('login-form.no-account')}} <router-link class="link" to="/register"> {{ $t('login-form.register')}}  </router-link>
            </p>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog v-model="dialog"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <Otp :user_id="user_id" @verified="success()" @close="dialog = false" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import crypto from '@/crypto'
  import Otp from '@/components/Otp'

  export default {
    name: 'Login',
    components: { Otp },
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'cartNumber'
      ])
    },
    data () {
      return {
        dialog: false,
        user_id: '',
        applicationName: { name: this.appName},
        email: '',
        password: '',
        seePassword: false,
        valid: true,
        emailRules: [
          v => !!v || this.$t('login-form.form.error-email'),
          v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.trim()) || this.$t('login-form.form.error-valid-email')
        ],
        passRules: [
          v => !!v || this.$t('login-form.form.error-password'),
        ],
      }
    },
    methods: {
      needOtp () {
        this.dialog = true
      },
      success () {
        this.dialog = false
        this.$router.push({ name: 'Home'})
      },
      login () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          let auth = {
            username: this.email.trim(),
            password: this.password,
          }

          axios
            .post('/api/v1/member/login', {}, {
              auth: auth
            })
            .then(response => {
              localStorage.setItem('prepp_n', crypto.encrypt(response.data.data.nama))
              localStorage.setItem('prepp_t', crypto.encrypt(response.data.data.token))
              localStorage.setItem('prepp_e', crypto.encrypt(this.email))

              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.$store.commit('setIsLogin', true)
              this.$store.commit('setIsLogin', true)
              this.$store.commit('setUserLogin', { name: response.data.data.nama, email: this.email, token: response.data.data.token})

              // if (this.cartNumber && this.cartNumber > 0 ) {
              //   this.$router.push({ name: 'Cart'}) 
              // } else {
                this.$router.push({ name: 'Home'}) 
              // }
            })
            .catch(error => {
              if (error.response.data.data && error.response.data.code === 'VER001') {
                this.user_id = error.response.data.data.id_pelanggan
                this.needOtp()
              }
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } 
      }
    },
    mounted () {
      if (this.isLogin) {
        this.$router.push({ name: 'Home'})
      }
      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', true)
      this.$store.commit('setActivePage', this.$t('login-register'))
    }
  }
</script>

<style scoped>
  .text {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
  }

  .text-2 {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
  }

  .p-text {
    margin-top: 15px;
    font-size: 14px;
    color: #000000;
  }

  .btn-text {
    font-weight: 500;
    color: #FFFFFF;
    text-decoration: none;
  }

  .login {
    color: #FFFFFF;
    position: relative;
    margin: auto;
    margin-top: 15%;
  }
  
  .box {
    padding: 30px;
  }

  .box-2 {
    text-align: center;
    padding: 15px;
  }

  .link {
    text-decoration: underline;
    color: #000000;
    cursor: pointer;
    font-weight: bold;
  }

  .link-f {
    text-decoration: none !important;
    color: #000000;
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
